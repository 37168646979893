//copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toggleSubscription } from '../../redux/actions/appActions';
import store from '../../redux/store';
import LoadingIndicator from '../Widgets/LoadingIndicator';
import { toggleLoadingIndicator } from '../../redux/actions/appActions';
import Routes from '../../containers/AppRouter/routes';
import { withRouter } from 'react-router';
import './index.css';
export class UserRegister extends Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                username: '',
                email: '',
                password: '',
                confirm_password: ''
            },
            messages: {}
        };
    }


    handleFormSubmit(event) {
        // event.preventDefault();
        var fData = new URLSearchParams();
        // console.log(this.state);
        fData.append('email', this.state.fields['email']);
        fData.append('username', this.state.fields['username']);
        fData.append('password', this.state.fields['password']);
        fData.append('subscription', 0);
        fData.append('subscription_plan_id', 'UNVERIFIED');
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        axios({
                method: 'post',
                //url: 'http://127.0.0.1:8080/api_v1-008/user_register.php', // for testing usage switchonoff 
                url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/user_register.php', // for production usage switchonoff
                data: fData,
                config
            })
            .then((response) => {
                //handle success
                // console.log('API Register connect...');
                // console.log(response.data) // for console usage switchonoff
                
                if (typeof response.data === 'string') {
                    if (response.data.match(/duplicate/i)) {
                        this.setState({ messages: {} });
                        let messages = {};
                        if (response.data.match(/duplicate.*email/i)) {
                            messages["email"] = "Error! - email already in use";
                        } else if (response.data.match(/duplicate.*username/i)) {
                            messages["username"] = "Error! - username already in use";
                        }
                        this.setState({ messages: messages });
                    }
                    const {
                        toggleLoadingIndicator,
                        is_loading_indicator,
                    } = this.props
                    toggleLoadingIndicator(!is_loading_indicator);
                } else if (response.data === 1) {
                    const {
                        setUserAction,
                        toggleLoadingIndicator,
                        is_loading_indicator,
                        history
                    } = this.props;

                    let fields = this.state.fields;
                    fields["subscription_plan_id"] = 'UNVERIFIED';
                    this.setState({ fields });
                    //setUserAction add in everything you received back from the API for newly activated registered users
                    // console.log(this.state) // for console usage switchonoff
                    setUserAction(this.state.fields["id"], this.state.fields["username"], this.state.fields["email"], 0, 0, this.state.fields['subscription_plan_id']);
                    // console.log(this.state) // for console usage switchonoff
                    toggleLoadingIndicator(!is_loading_indicator);
                    history.push(Routes.userregisterverify);
                }


            })
            .catch((error) => {
                //handle error
                const {
                    toggleLoadingIndicator,
                    is_loading_indicator,
                } = this.props
                toggleLoadingIndicator(!is_loading_indicator);
                // console.log(error);
            });
    }

    handleValidation() {
        let fields = this.state.fields;
        let messages = {};
        let formIsValid = true;
        //First Name validation empty

        //username validation empty
        if (!fields["username"]) {
            formIsValid = false;
            messages["username"] = "Cannot be empty";
        }
        //username validation format
        if (typeof fields["username"] !== "undefined") {
            if (!fields["username"].match(/^[a-zA-Z0-9]+$/)) {
                formIsValid = false;
                messages["username"] = "Error! - only letters and numbers, no spaces";
            }
        }
        //Password
        if (!fields["password"]) {
            formIsValid = false;
            messages["password"] = "Cannot be empty";
        }
        if (typeof fields["password"] !== "undefined") {
            if (!fields["password"].length >= 8) {
                formIsValid = false;
                messages["password"] = "Minimum length of password must be 8.";
            }
            if (!fields["password"].match(/[a-zA-Z]/) || !fields["password"].match(/[0-9]/) || !fields["password"].match(/_|-|!|\*|\./)) {
                formIsValid = false;
                messages["password"] = "Must include letters, number(s), and at least one symbol: '!', '_', '-', '*'";
            }
            //check for 
        }
        if (!fields["confirm_password"]) {
            formIsValid = false;
            messages["confirm_password"] = "Cannot be empty";
        } else if (typeof fields["confirm_password"] !== "undefined") {
            if (fields["password"] !== fields["confirm_password"]) {
                formIsValid = false;
                messages["confirm_password"] = "Password confirmation does not match";
            }
        }
        //Email
        if (!fields["email"]) {
            formIsValid = false;
            messages["email"] = "Error! - cannot be empty";
        }
        if (typeof fields["email"] !== "undefined") {
            let lastAtPos = fields["email"].lastIndexOf('@');
            let lastDotPos = fields["email"].lastIndexOf('.');
            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
                formIsValid = false;
                messages["email"] = "Error! - email is not valid format";
            }
        }
        this.setState({ messages: messages });
        return formIsValid;
    }
    checkSubmit(e, is_loading_indicator, toggleLoadingIndicator) {
        e.preventDefault();

        if (this.handleValidation()) {
            toggleLoadingIndicator(!is_loading_indicator);
            this.setState({ messages: {} })
            this.handleFormSubmit();
        } else {
            // alert("Form has messages.");
        }
    }
    //todo  do not handle every single change to set the state its pointless... just set it after its validated above 
    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }


    render() {
        const {
            toggleLoadingIndicator,
            is_loading_indicator,
        } = this.props;
        return (
            <React.Fragment>
            <div className="register">
                <h2 className="pleft">Register to Big Stream</h2>
                
                <form>
                    <p><small>Note: all fields required</small></p>
                    <p><label>Username</label> <span style={{color: "red"}}>{this.state.messages["username"]}</span></p>
                    <input type="text" name="username" value={this.state.username} onChange={this.handleChange.bind(this, "username")}/>
                    <br />
                    <label>Email</label> <span style={{color: "red"}}>{this.state.messages["email"]}</span><br />
                    <input type="email" name="email"  value={this.state.email} onChange={this.handleChange.bind(this, "email")}/>
                    <br />
                    <label>Password</label> <span style={{color: "red"}}>{this.state.messages["password"]}</span><br />
                    <small>Minimum length of 8, must include letters, and one number, and acceptable symbol: ! _ - . *</small><br/>
                    <input type="password" name="password"  value={this.state.password} onChange={this.handleChange.bind(this, "password")}/>
                    <br />
                    <label>Confirm Password</label> <span style={{color: "red"}}>{this.state.messages["confirm_password"]}</span><br />
                    <input type="password" name="confirm_password"  value={this.state.confirm_password} onChange={this.handleChange.bind(this, "confirm_password")}/>
                    <br />
                    <input className="pleft" type="submit" onClick={e => this.checkSubmit(e, is_loading_indicator, toggleLoadingIndicator)} value="Register to BigStream" />
                </form>
                {/*<p className="pleft"><small>Note: All newly registered users will automatically be subscribed to the Big Stream newsletter. <br />If you wish to un-subscribe you may in your user profile.</small></p>*/}
                </div>
            </React.Fragment>
        );
    }
}
UserRegister.propTypes = {
    setUserAction: PropTypes.func,
    id: PropTypes.string,
    username: PropTypes.string,
    email: PropTypes.string,
    newsletter: PropTypes.number,
    subscription: PropTypes.number,
    subscription_plan_id: PropTypes.string,
    expiry_date: PropTypes.string,
    toggleLoadingIndicator: PropTypes.func,
    is_loading_indicator: PropTypes.bool,

};
UserRegister.defaultProps = {
    setUserAction: () => {},
    id: null,
    username: null,
    email: null,
    newsletter: null,
    subscription: -1,
    subscription_plan_id: null,
    expiry_date: null,
    toggleLoadingIndicator: () => {},
    is_loading_indicator: false,
}
export const mapStateToProps = state => ({
    id: state.user.id,
    username: state.user.username,
    email: state.user.email,
    newsletter: state.user.newsletter,
    subscription: state.user.subscription,
    subscription_plan_id: state.user.subscription_plan_id,
    expiry_date: state.user.expiry_date,
    is_loading_indicator: state.app.is_loading_indicator,
});
export const mapDispatchToProps = dispatch => ({
    toggleLoadingIndicator: value => dispatch(toggleLoadingIndicator(value)),
});
// export default UserLogin;
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserRegister));
// copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import store from '../../redux/store';
import axios from 'axios';
import { connect } from 'react-redux';
import { setMedia } from '../../redux/actions/currentMediaActions';
import HorizontalScrollBar from '../../components/HorizontalScrollBar';
import { Helmet } from "react-helmet";
// import bse_feed from '../../data/bse_feed.json';
import { getResources, getSearchResources } from '../../components/Helpers';
import { toggleCategory } from '../../redux/actions/appActions';
// import { getMedia, getResources } from '../../components/Helpers'
// import './index.css';

export class Category extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bse_feed_playlists: null,
            bse_feed_catlist: null,
            bse_feed_alltitles: null,
            bse_media: {},
            user_media_detail: null,
            bse_media_detail: {},
            fields: { search: '' }
        };
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    componentDidMount() {
        axios.get('data-876dba1376e6c3ee12a07a6e0db389d6/bse_feed-playlists.json')
            .then((response) => {
                // console.log(response.data)
                const { setMedia, history, is_category } = this.props;
                this.setState({ bse_feed_playlists: response.data });

                if (this.state.bse_feed_playlists) {
                    const category = this.state.bse_feed_playlists.playlists.filter(bse_playlist => {

                        if (bse_playlist.name === is_category) {
                            this.setState({ bse_feed_catlist: bse_playlist });
                            // console.log(this.state.bse_feed_catlist);
                        }
                    });
                }
                // console.log(this.state.bse_feed_playlists);
                // // const state = store.getState();
                // // const playlist = state.custom1.playlist;
                // // this.state.user_media_detail = getResources(playlist, this.state.bse_media, 1);
                // // if (this.state.user_media_detail.length == 0) {
                // //     this.state.user_media_detail = null;
                // // }
                // // console.log(this.state.bse_feed.playlists);
                // this.state.bse_feed.playlists.map(bse_playlist => {
                //     console.log(bse_playlist)
                //     this.setState({ bse_media_detail: getResources(bse_playlist.itemIds, this.state.bse_media, 0) })
                // });
                // console.log(this.state.bse_media_detail)
            })
            .catch((error) => {
                // console.log(error);
            })

        axios.get('data-876dba1376e6c3ee12a07a6e0db389d6/bse_feed-alltitles.json')
            .then((response) => {
                // console.log(response.data)
                this.setState({ bse_feed_alltitles: response.data });
                // console.log(this.state.bse_feed_alltitles);
            })
            .catch((error) => {
                // console.log(error);
            })
    }

    render() {
        const { setMedia, history, toggleCategory, is_category } = this.props;
        const state = store.getState();
        console.log(is_category);
        const user_playlist = state.custom1.playlist;
        let user_media_detail = null;
        if (is_category === 'user playlist') {
            if (user_playlist && this.state.bse_feed_alltitles) {
                const media_list = [];
                for(var k in user_playlist) {
                    media_list.push(k);
                    user_media_detail = getResources(media_list, this.state.bse_feed_alltitles, 1);
                    if (user_media_detail && user_media_detail.length > 0) {
                        // console.log(user_media_detail)
                    } else {
                        user_media_detail = null;
                    }             
                }
            }
        }

        const meta_title = "BSE - " + is_category.toUpperCase();
        const meta_description = {
            name: "description",
            content: "BSE - Big Stream Entertainment " + is_category.toUpperCase() + " streaming and entertainment portal presented by Big Screen Entertainment Group (Stock: BSEG)"
        }
        const meta_keywords = {
            name: "keywords",
            content: "big stream, bseg, big screen entertainment, big stream, entertainment, " + is_category
        }

        return (
            <div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{meta_title}</title>
            <link rel="canonical" href="https://bigstreamentertainment.com/category" />
            <meta { ...meta_description } />
            <meta { ...meta_keywords  } />
        </Helmet>
        <div className="search-container category">
            <div style={{margin: '9px 5px 0 0' }}><strong>Search: </strong></div>
            <input className="search_input" type='text' name='search' placeholder="Type to search titles..." value={this.state.fields['search']} onLoad={this.handleChange.bind(this, 'search')} onChange={this.handleChange.bind(this, 'search')}/>
        </div>
        <div className="tile-container">
            {   
                (user_media_detail && state.user.subscription >= 30 && is_category === 'user playlist') &&  // subscription 30 and up is paying customer
                <HorizontalScrollBar key={'USER PLAYLIST'}
                    title={'USER PLAYLIST'}
                    clickAction={setMedia}
                    resources={user_media_detail}
                    history={history}
                />
            }
            {

                (this.state.bse_feed_catlist && this.state.fields['search'].length === 0) &&
                <HorizontalScrollBar key={this.state.bse_feed_catlist.name}
                        title={this.state.bse_feed_catlist.name.toUpperCase()}
                        clickAction={setMedia}
                        resources={this.state.bse_feed_catlist.itemIds}
                        history={history}
                    />
            }
            {

                (this.state.bse_feed_catlist && this.state.fields['search'].length > 0) &&
                <React.Fragment>
                    <HorizontalScrollBar key={'SEARCH'}
                        title={this.state.bse_feed_catlist.name.toUpperCase() + ' SEARCH RESULTS'}
                        clickAction={setMedia}
                        resources={getSearchResources(this.state.bse_feed_catlist.itemIds, this.state.fields['search'])}
                        history={history}
                    />
                </React.Fragment>
                
            }
          </div>
      </div>
        );
    }
}

Category.propTypes = {
    setMedia: PropTypes.func,
    toggleCategory: PropTypes.func,
    is_category: PropTypes.string,
}

Category.defaultProps = {
    toggleCategory: () => {},
    setMedia: () => {},
    is_category: null,
}

export const mapStateToProps = state => ({
    is_category: state.app.is_category,
});

export const mapDispatchToProps = dispatch => ({
    toggleCategory: value => dispatch(toggleCategory(value)),
    setMedia: (id, title, desc, url, thumbnail, license1, license2, videoJsOptions) => dispatch(setMedia(id, title, desc, url, thumbnail, license1, license2, videoJsOptions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Category);
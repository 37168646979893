// copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import store from '../../redux/store';
import axios from 'axios';
import { connect } from 'react-redux';
import { setMedia } from '../../redux/actions/currentMediaActions';
import HorizontalScrollBar from '../../components/HorizontalScrollBar';
import { Helmet } from "react-helmet";
// import bse_feed from '../../data/bse_feed.json';
// import bse_feed from 'data-876dba1376e6c3ee12a07a6e0db389d6/bse_feed.json';
import { getResources, getSearchResources } from '../../components/Helpers';
// import ModAdTop1 from '../../components/ModAdTop1';
// import { getMedia, getResources } from '../../components/Helpers'
// import { setCustom1 } from '../../redux/actions/customActions';
import { toggleCategory } from '../../redux/actions/appActions';
import './index.css';





export class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bse_feed_playlists: null,
            bse_feed_alltitles: null,
            bse_media: {},
            user_media_detail: null,
            bse_media_detail: {},
            fields: { search: '' },
            // bse_user_playlist: []
        };
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }


    componentDidMount() {
        const {            
            history,
            toggleCategory,
        } = this.props;
        // console.log(this.props)
        toggleCategory('home')

        axios.get('data-876dba1376e6c3ee12a07a6e0db389d6/bse_feed-playlists.json')
            .then((response) => {
                // console.log(response.data)
                this.setState({ bse_feed_playlists: response.data });
                // console.log(this.state.bse_feed_playlists);
                // // const state = store.getState();
                // // const playlist = state.custom1.playlist;
                // // this.state.user_media_detail = getResources(playlist, this.state.bse_media, 1);
                // // if (this.state.user_media_detail.length == 0) {
                // //     this.state.user_media_detail = null;
                // // }
                // // console.log(this.state.bse_feed.playlists);
                // this.state.bse_feed.playlists.map(bse_playlist => {
                //     console.log(bse_playlist)
                //     this.setState({ bse_media_detail: getResources(bse_playlist.itemIds, this.state.bse_media, 0) })
                // });
                // console.log(this.state.bse_media_detail)
            })
            .catch((error) => {
                // console.log(error);
            })

        axios.get('data-876dba1376e6c3ee12a07a6e0db389d6/bse_feed-alltitles.json')
            .then((response) => {
                // console.log(response.data)
                this.setState({ bse_feed_alltitles: response.data });
                // console.log(this.state.bse_feed_alltitles);
            })
            .catch((error) => {
                // console.log(error);
            })
    }



    render() {
        const { setMedia, history } = this.props;
        
        const state = store.getState();
        
        const user_playlist = state.custom1.playlist;
            
        let user_media_detail = null;
        // console.log(this.state.bse_feed)
        if (user_playlist && this.state.bse_feed_alltitles) {
            // console.log(user_playlist);
            const media_list = [];
            for(var k in user_playlist) media_list.push(k);
            // console.log(this.state.bse_feed_alltitles);
            // this.state.bse_feed_alltitles.map(item => {            
            //     // console.log(playlist)
                user_media_detail = getResources(media_list, this.state.bse_feed_alltitles, 1);
                if (user_media_detail && user_media_detail.length > 0) {
                    // console.log(user_media_detail)
                } else {
                    user_media_detail = null;
                }
            //     // user_media_detail = getResources(user_playlist, this.state.bse_feed.playlist, 1);
            // })
           
        }

        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>BSE - Home</title>
                    <meta name="description" content="BSE - Big Stream Entertainment streaming and entertainment portal presented by Big Screen Entertainment Group (Stock: BSEG)" />
                    <meta name="keywords" content="big stream, bseg, big screen entertainment, big stream, entertainment" />
                    <link rel="canonical" href="https://bigstreamentertainment.com" />
                    {/*<meta property="og:title" content={retData.title} />*/}
                    {/*<meta property="og:image" content={retData.thumbnail} />*/}
                    {/*<meta property="og:image:type" content="image/jpg" />*/}
                </Helmet>
                <div className="search-container">
                    <div style={{margin: '9px 5px 0 0' }}><strong>Search: </strong></div>
                    <form autoComplete="off">
                        {/*<label className='search_header'>SEARCH</label>*/}
                        <input autoComplete="off" className="search_input" type='search' name='search' placeholder="Type to search titles..." value={this.state.fields['search']} onLoad={this.handleChange.bind(this, 'search')} onChange={this.handleChange.bind(this, 'search')}  />
                    </form>
                </div>
                <div className="tile-container">
                    {   
                        (user_media_detail && state.user.subscription >= 30) &&  // subscription 30 and up is paying customer

                        <HorizontalScrollBar key={'USER PLAYLIST'}
                            title={'USER PLAYLIST'}
                            clickAction={setMedia}
                            resources={user_media_detail}
                            history={history}
                        />
                        
                    }
                    {   
                        (user_media_detail && state.user.subscription < 30) &&  // subscription 30 and up is paying customer

                        <HorizontalScrollBar key={'USER LICENSED MEDIA'}
                            title={'USER LICENSED MEDIA'}
                            clickAction={setMedia}
                            resources={user_media_detail}
                            history={history}
                        />
                        
                    }
                    {

                        (this.state.bse_feed_playlists && this.state.fields['search'].length === 0) &&
                        this.state.bse_feed_playlists.playlists.map(playlist => {
                            return(<HorizontalScrollBar key={playlist.name}
                                title={playlist.name.toUpperCase()}
                                clickAction={setMedia}
                                resources={playlist.itemIds}
                                history={history}
                            />);
                            {/*console.log(playlist.itemIds);    */}
                        })
                    }
                    {

                        (this.state.bse_feed_alltitles && this.state.fields['search'].length > 0) &&
                        <React.Fragment>
                            <HorizontalScrollBar key={'SEARCH'}
                                title={'SEARCH RESULTS'}
                                clickAction={setMedia}
                                resources={getSearchResources(this.state.bse_feed_alltitles, this.state.fields['search'])}
                                history={history}
                            />
                        </React.Fragment>
                        
                    }
                   
                   
                </div>
            </div>
        );
    }
}
Home.propTypes = {
    setMedia: PropTypes.func,
    // setCustom1: PropTypes.func,
}
Home.defaultProps = {
    setMedia: () => {},
    bse_feed: [],
    // setCustom1: () => {},
}

export const mapStateToProps = state => ({
    id: state.user.id,
    username: state.user.username,
    email: state.user.email,
    newsletter: state.user.newsletter,
    subscription: state.user.subscription,
    subscription_plan_id: state.user.subscription_plan_id,
    expiry_date: state.user.expiry_date,
    is_search_menu: state.app.is_search_menu,
    is_submenu_about: state.app.is_submenu_about,
    is_submenu_category: state.app.is_submenu_category,
    is_category: state.app.is_category,
    is_subscription: state.app.is_subscription,
    is_loading_indicator: state.app.is_loading_indicator,
    // playlist: state.custom1.playlist,

});

export const mapDispatchToProps = dispatch => ({
    toggleCategory: value => dispatch(toggleCategory(value)),
    setMedia: (mku, title, desc, url, thumbnail, license1, license2, videoJsOptions) => dispatch(setMedia(mku, title, desc, url, thumbnail, license1, license2, videoJsOptions)),
    // setCustom1: (playlist) => dispatch(setCustom1(playlist)),
});
export default connect(null, mapDispatchToProps)(Home);
import * as ActionTypes from '../actions/currentMediaActions';

export const initialState = {
  mku: null,
  title: null,
  description: null,
  url: null,
  thumbnail: null,
  license1: null,
  license2: null,
  videoJsOptions: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_MEDIA:
      return Object.assign({}, state, {
        mku: action.mku,
        title: action.title,
        description: action.desc,
        url: action.url,
        thumbnail: action.thumbnail,
        license1: action.license1,
        license2: action.license2,
        videoJsOptions: action.videoJsOptions
      });
    default:
        return state;
  }
};

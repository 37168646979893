
//copyright: Big Screen Entertainment Group Inc.
export const SET_MEDIA = 'SET_MEDIA';

export function setMedia(mku, title, desc, url, thumbnail, license1, license2, videoJsOptions) {
  return {
    type: SET_MEDIA,
    mku,
    title,
    desc,
    url,
    thumbnail,
    license1,
    license2,
    videoJsOptions
  };
}
//copyright: Big Screen Entertainment Group Inc.
import React, { Component } from 'react';
import UserRegister from '../../components/UserRegister';
import UserRegisterVerify from '../../components/UserRegisterVerify';
import UserLogin from '../../components/UserLogin';
import UserSubscription from '../../components/UserSubscription';
import { Helmet } from "react-helmet";
import { logout } from '../../components/Helpers';
import { loadReCaptcha } from 'react-recaptcha-v3';
import { ReCaptcha } from 'react-recaptcha-v3';

import Routes from '../AppRouter/routes';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import store from '../../redux/store';
import axios from 'axios';
import { setUser } from '../../redux/actions/userActions';
import { setCustom1 } from '../../redux/actions/customActions';
import { toggleSubscription } from '../../redux/actions/appActions';
// import LoadingIndicator from '../../components/Widgets/LoadingIndicator';
import { toggleCategory } from '../../redux/actions/appActions';
import { toggleLoadingIndicator } from '../../redux/actions/appActions';
import './index.css';
export class UserProfile extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            fields: { verify_code: '' },
            messages: {},
        };
    }
    componentDidMount() {
        //console.log('loadReCaptcha'); // for testing usage switchonoff
        loadReCaptcha('6LdSUvwZAAAAAA1UnYD_LkhODLCg9CsyJubx5udd'); // for production usage switchonoff

        //PLAYLIST CHECK - ONLOAD READY CHECK TO SEE IF MEDIA IS ALREADY ADDED 
        //PLAYLIST CHECK - IF SO THEN NOTIFY
        //TODO add remove functionality

        const state = store.getState();
        var fData = new URLSearchParams();
        fData.append('id', state.user.id);
        // console.log(state);
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        axios({
                method: 'post',
                //url: 'http://127.0.0.1:8080/api_v1-008/user_playlist_list.php', // for testing usage switchonoff
                url: 'https://bigstreamentertainment.com/api_D08B56117BBE0438A06072F29D5A3F1A70D705B227E63FF7187667880A80F39C/user_playlist_list.php', // for production usage switchonoff
                data: fData,
                config
            })
            .then((response) => {
                //handle success
                // console.log(response.data);
                // console.log(typeof response.data);
                // console.log(this.state);
                if (response.data.message === 'playlist-list') {
                    this.setState({ playlist: true });
                } else if (response.data.message(/duplicate entry/i)) {
                    // nothing for now
                }

                //TODO need to check if it already exists in componentDidMount.
            })
            .catch((error) => {
                // console.log('ERROR ' + error)
            }); //end axios playlist exist
    }
    componentWillUnmount() {}
    verifyCallback(recaptchaToken) {
        // Here you will get the final recaptchaToken!!!  
        // console.log(recaptchaToken); // for console usage switchonoff
    }
    updateToken = () => {
        // you will get a new token in verifyCallback
        this.recaptcha.execute();
    }

    //might just move all logout logic here
    handleLogout(setUser, toggleSubscription, is_subscription, subscription, state) {
        let messages = {};

        messages['verify_sent'] = false;
        this.setState({ messages: messages });
        if (subscription >= 30) { // subscription 30 and above are paying
            toggleSubscription(!is_subscription);
            // console.log(state);
            const {
                setCustom1,
            } = this.props;
            // console.log(setCustom1)
            setCustom1(null);
        }
        logout(setUser);
    }
    subscriptionTier = () => {
        let subscription = parseInt(this.props.subscription);
        switch (subscription) {
            case 0:
                return 'Unverified Email';
            case 10:
                return 'Expired Trial - please subscribe to regain access to play media library.  Subscribing also provides added features such as user playlist functionality.';
            case 20:
                return 'Registered Trial - You have access to play any media in the library.  Subscribing also provides added features such as user playlist functionality.';
            case 30:
                return 'Registered Subscription - This subscription provides full library access, as well as user added playlists.';
            default:
                return 'Guest';
        }
    }
    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }
    handleVerifyResend(e, is_loading_indicator, toggleLoadingIndicator) {
        e.preventDefault();
        toggleLoadingIndicator(!is_loading_indicator);
        this.handleSubmitVerifyResend();
    }
    handleVerifyCode(e, is_loading_indicator, toggleLoadingIndicator) {
        e.preventDefault();
        toggleLoadingIndicator(!is_loading_indicator);
        this.handleSubmitVerifyCode();
    }
    render() {
        const {
            setUser,
            toggleSubscription,
            is_subscription,
            toggleLoadingIndicator,
            is_loading_indicator,
            toggleCategory,
            is_category,
            match,
            location,
            history
        } = this.props;
        // let access_token = localStorage.getItem('access_token');
        // let expire_at = localStorage.getItem('expire_at');
        const state = store.getState();
        this.id = state.user.id;
        this.username = state.user.username;
        this.email = state.user.email;
        this.newsletter = state.user.newsletter;
        this.subscription = state.user.subscription;
        this.subscription_plan_id = state.user.subscription_plan_id;
        this.expiry_date = state.user.expiry_date;
        const playlist = state.custom1.playlist;
        
        return (
            <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>BSE - User Profile</title>
                <link rel="canonical" href="https://bigstreamentertainment.com/userprofile" />
                <meta name="description" content="BSE - Big Stream Entertainment streaming and entertainment portal user profile and information page (Stock: BSEG)" />
                <meta name="keywords" content="big stream, bseg, big screen entertainment, big stream, entertainment, user register, login, profile" />
            </Helmet>
            <div className="user_profile">
                <h1>User Portal</h1>
                {//if
                    //USER MESSAGES AND SUBSCRPTIONS
                    this.subscription >= 0 && // subscription 0 is unverified user, and up to paying user
                        <React.Fragment>
                            <div className="tile-row-standard">
                                <div className="user_actions tile-left">
                                    <h2>Welcome {this.username}</h2>
                                    <h3>Plan: {this.subscription_plan_id}</h3>
                                </div>
                            </div>
                            <div className="tile-row-standard">
                                <div className="user_actions tile-left">
                                    <h3>Actions</h3>
                                    <button className="btn btn-primary" onClick = {() => this.handleLogout(setUser, toggleSubscription, is_subscription, this.subscription, state)}>Log out</button>
                                    {(playlist && state.user.subscription >= 30) && // subscription numbers 30 and above are paying
                                        ((Object.keys(playlist)).length) > 0 &&
                                            <button className="btn btn-primary" onClick = {() => {
                                                toggleCategory('user playlist');
                                                history.push(Routes.userplaylist);
                                            }}>Playlist</button>
                                        
                                    }
                                </div>
                                <div className="user_actions tile-left">
                                    <h3>Messages</h3>
                                     {this.state.messages['status'] && // subscription 20 registered user, and 10, 0 subscriptions have an expiry date
                                        <React.Fragment>
                                            <p><span style={{color: '#00b4f9'}}>{this.state.messages['status']}</span></p>
                                        </React.Fragment>
                                    }
                                     {this.subscription <= 20 && // subscription 20 registered user, and 10, 0 subscriptions have an expiry date
                                        <React.Fragment>
                                            {state.user.expiry_date &&
                                                <p>Trial expiry date: {state.user.expiry_date}</p>
                                            }
                                        </React.Fragment>
                                    }
                                    <p>{this.subscriptionTier(this.subscription)}</p>
                                </div>
                            </div>
                            
                            
                            <div className="tile-row-standard">
                            <div className="user_subscriptions">
                                {this.subscription !== 0 &&                                    
                                    <UserSubscription key={'Subscription'}
                                        setUserAction={setUser}
                                    />
                                }
                            </div>
                            </div>
                            
                        </React.Fragment>
                     
                }
                {//if
                    //GUEST (-1 subscription) is true 
                    this.subscription === -1 &&
                        <UserLogin key={'Login'}
                            setUserAction={setUser}
                        />
                }

               
                
                {//if
                    //USER REGISTRATION
                    this.subscription === -1 &&
                        <UserRegister key={'Register'} setUserAction={setUser} />
                }
                <ReCaptcha
                    ref={ref => this.recaptcha = ref}
                    sitekey="6LdSUvwZAAAAAA1UnYD_LkhODLCg9CsyJubx5udd"
                    action='submit'
                    verifyCallback={this.verifyCallback}
                />
            </div>
            </div>
        );
    };
}







UserProfile.propTypes = {
    setUser: PropTypes.func,
    toggleSubscription: PropTypes.func,
    is_subscription: PropTypes.bool,
    toggleLoadingIndicator: PropTypes.func,
    is_loading_indicator: PropTypes.bool,
    toggleCategory: PropTypes.func,
    is_category: PropTypes.string,
    setCustom1: PropTypes.func,
}
UserProfile.defaultProps = {
    setUser: () => {},
    toggleSubscription: () => {},
    is_subscription: false,
    toggleLoadingIndicator: () => {},
    is_loading_indicator: false,
    toggleCategory: () => {},
    setCustom1: () => {},
}
export const mapStateToProps = state => ({
    id: state.user.id,
    username: state.user.username,
    email: state.user.email,
    newsletter: state.user.newsletter,
    subscription: state.user.subscription,
    subscription_plan_id: state.user.subscription_plan_id,
    expiry_date: state.user.expiry_date,
    is_subscription: state.app.is_subscription,
    is_loading_indicator: state.app.is_loading_indicator,
    is_category: state.app.is_category,
    playlist: state.custom1.playlist,
});
export const mapDispatchToProps = dispatch => ({
    toggleCategory: value => dispatch(toggleCategory(value)),
    setUser: (id, username, email, newsletter, subscription, subscription_plan_id, expiry_date) => dispatch(setUser(id, username, email, newsletter, subscription, subscription_plan_id, expiry_date)),
    toggleSubscription: value => dispatch(toggleSubscription(value)),
    toggleLoadingIndicator: value => dispatch(toggleLoadingIndicator(value)),
    setCustom1: (playlist) => dispatch(setCustom1(playlist)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserProfile));